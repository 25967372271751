import AmazonPayButtonMgr from 'amazon/AmazonPayButtonMgr';
import QuantitySelector from 'oneapp/src/components/QuantitySelector';
import CSRF from 'oneapp/src/utils/CSRF';
import ProductTileBuilder from '../../../src/classes/productTile/Builder';
import ProductTileDirector from '../../../src/classes/productTile/Director';
import ProductTile from '../../../src/classes/productTile/ProductTile';

(function(app, $) {
	function MinicartFlyout(config) {
		app.flyouts.Flyout.call(this, 'minicart', config);
		this.disableAutoHide = false;
	}

	MinicartFlyout.prototype = Object.create(app.flyouts.Flyout.prototype);
	MinicartFlyout.prototype.constructor = app.flyouts.Flyout;

	MinicartFlyout.prototype.open = function(params = {}) {
		this.markup = getMarkup();
		const minicartParams = {
			...params,
			appendToElement: app.configs.minicart.selector,
			skipXSSValidation: true
		};

		app.flyouts.Flyout.prototype.open.call(this, minicartParams);
		$(document).trigger('minicart.flyout.open');
	};

	MinicartFlyout.prototype.close = function(params) {
		$(document).trigger('minicart.flyout.close');
		app.flyouts.Flyout.prototype.close.call(this, params);
	};

	MinicartFlyout.prototype.update = function(params = {}) {
		this.markup = getMarkup();
		const $container = $(app.configs.minicart.selector);
		const currentScrollPosition = $container.find('.js-minicart--product-tiles').scrollTop();
		const focusableElementID = $(document.activeElement).data('focusable-element-id');

		$container.find('.js-slide-flyout-content.js-minicart-flyout').replaceWith(this.markup);
		this.$markup = $container.find('.js-slide-flyout-wrapper');

		$(document).trigger('minicart.flyout.update');
		params.focusableElementID = focusableElementID;
		app.flyouts.Flyout.prototype.update.call(this, params);

		if (focusableElementID) {
			$container.find('.js-minicart--product-tiles').scrollTop(currentScrollPosition);
		}
	};

	MinicartFlyout.prototype.initMarkup = function(params) {
		app.flyouts.Flyout.prototype.initMarkup.call(this, params);
		initRemoveButtons.call(this);
		initAmazonPay.call(this);
		initGooglePay.call(this);
		initSingInButton.call(this);
		initCouponBlock.call(this);
		initAccordions.call(this, params.accordionActive);
		initQuantitySelectorBlock.call(this);
		initProceedButton.call(this);
	};

	function getMarkup() {
		if (app.minicart.lineItemsQuantity > 0) {
			return app.util.renderTemplate(
				$('#minicartFlyoutTemplate').html(),
				{
					...app.minicart,
					productTiles: getProductTilesMarkup(),
					skipXSSValidation: true
				}
			);
		} else {
			return app.util.renderTemplate(
				$('#emptyMinicartFlyoutTemplate').html(),
				{
					...app.minicart
				}
			);
		}
	}

	function getProductTilesMarkup() {
		let productTileMarkups = '';

		for (const productTile of app.minicart.products) {
			if (productTile.isGiftCart) {
				productTileMarkups += new ProductTile($('#giftcartTile').html(), productTile);
			} else {
				const builder = new ProductTileBuilder(productTile);
				const director = new ProductTileDirector(builder);
				director.buildMinicartProductTile();
				productTileMarkups += new ProductTile($('#minicartProductTile').html(), builder.getResult());
			}
		}

		return productTileMarkups;
	}

	function initRemoveButtons() {
		const $minicartRemoveButtons = this.$markup.find('.js-mini_cart-remove');
		const flyout = this;

		$minicartRemoveButtons.on('click', function(e) {
			e.preventDefault();
			const $document = $(document);

			$.ajax(this.href, {
				xhrFields: app.corsOrigin ? { withCredentials: true } : undefined
			}).done(function(data) {
				app.minicart.update(data);

				if (app.minicart.lineItemsQuantity === 0) {
					if (app.configs.minicart.closeAfterRemovingLastProd) {
						flyout.close();
					} else {
						flyout.update({
							autoClose: app.configs.minicart.closeWithDelayAfterRemovingLastProd
						});
						$document.trigger('minicart.afterload');
					}
				} else {
					flyout.update();
					$document.trigger('minicart.afterload');
				}

				$document.trigger('minicart.product.removed');
			}).fail(function() {
				window.location = window.location.href;
			});
		});
	}

	function initAccordions(accordionActive = false) {
		const $accordion = this.$markup.find('.js-minicart_accordion');
		app.accordion.initAccordions($accordion, {
			active: accordionActive
		});
	}

	function initAmazonPay() {
		if (app.amazon && app.amazon.pay.enabled) {
			const amazonPayButtons = this.$markup.find('.js-amazon-pay-button');

			if (amazonPayButtons.length > 0) {
				AmazonPayButtonMgr.render(amazonPayButtons.get(0), app.amazon.pay, 'Cart');
			}
		}
	}

	function initGooglePay() {
		if (app.components.global.googlepay) {
			app.components.global.googlepay.init();
		}
	}

	function initSingInButton() {
		const $minicartSignInBtn = this.$markup.find('.js-mini_cart-signin-btn');

		$minicartSignInBtn.on('click', function() {
			app.flyoutMgr.open('login', {
				keepFocus: true
			});
		});
	}

	function initCouponBlock() {
		const flyout = this;
		const $couponForm = $('.js-coupon_form');

		if ($couponForm.length > 0) {
			this.$markup.on('submit', '.js-coupon_form', function(e) {
				e.preventDefault();
				const $this = $(this);

				if ($this.valid()) {
					const data = $this.serialize();

					$.ajax({
						url: $this.attr('action'),
						data: data
					}).done(function(resp) {
						if (!resp.success) {
							if (resp.redirectURL) {
								app.page.redirect(resp.redirectURL);
							}

							$couponForm.find('.f-error_text').text(resp.message);
							$couponForm.find('.f-field').removeClass('f-state-valid');
						} else {
							app.minicart.update(resp);
							flyout.update({
								accordionActive: 0
							});
							$(document).trigger('minicart.afterload');
						}
					});
				}
			});
		}
	}

	function updateProductLineItemQuantity(payload = {}) {
		const flyout = this;

		return $.ajax({
			type: 'POST',
			url: app.urls.updateProductLineItem,
			data: payload
		}).done(function(data) {
			app.minicart.update(data);
			flyout.update();
			$(document).trigger('minicart.afterload');
		});
	}

	function initQuantitySelectorBlock() {
		const flyout = this;

		if (app.minicart.products && Array.isArray(app.minicart.products)) {
			app.minicart.products.forEach((product) => {
				if (product.quantitySelector) {
					new QuantitySelector({
						$increaseQuantityButton: $(`.js-mini_cart-increase-quantity[data-line-item-id=${product.uuid}]`),
						$decreaseQuantityButton: $(`.js-mini_cart-decrease-quantity[data-line-item-id=${product.uuid}]`),
						$quantityDisplayBlock: $(`.js-mini_cart-item-quantity[data-line-item-id=${product.uuid}]`).first(),
						maximumQuantityLimitation: app.preferences.maxLineItemQty,
						minimumQuantityLimitation: 1,
						updateQuantityCallBack: (quantity) => {
							const payload = {
								uuid: product.uuid,
								Quantity: quantity
							};

							return CSRF.populatePayloadByCSRFToken(payload)
								.then((payload) => updateProductLineItemQuantity.call(flyout, payload));
						},
						skipQuantityViewUpdating: true
					}).initializeQuantitySelector();
				}
			});
		}
	}

	function initProceedButton() {
		if (app.minicart.approachingDiscounts) {
			const $minicartProceedBtn = this.$markup.find('.js-minicart-checkout-btn');
			const bonusDiscount = app.minicart.approachingDiscounts.bonusDiscounts.length > 0 && app.minicart.approachingDiscounts.bonusDiscounts[0];

			if (bonusDiscount) {
				const missingDiscountProducts = app.minicart.products.reduce((tempVal, product) => {
					if (product.isBonusProduct && product.bonusDiscount.promotionID === bonusDiscount.promotionID) {
						return tempVal - 1;
					} else {
						return tempVal;
					}
				}, bonusDiscount.maxBonusItems);

				if (missingDiscountProducts > 0) {
					$minicartProceedBtn.on('click', function(e) {
						e.preventDefault();

						app.flyoutMgr.open('freeGift', {
							discount: bonusDiscount
						});
					});
				}
			}
		}
	}

	var config = app.configs.flyoutComponent;

	app.flyouts.minicart = new MinicartFlyout(config);
})((window.app = window.app || {}), jQuery);
