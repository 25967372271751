(function(app, $) {
	const $doc = $(document);

	function Flyout(type, config) {
		this.type = type;
		this.isOpened = false;
		this.direction = config[type] && config[type].direction ? config[type].direction : config.defaultDirection;
		this.closeBtnAriaLabel = config[type] && config[type].closeBtnAriaLabel ? config[type].closeBtnAriaLabel : config.defaultCloseBtnAriaLabel;
		this.closeBtnText = config[type] && config[type].closeBtnText ? config[type].closeBtnText : config.defaultCloseBtnText;
		this.isAutoCloseEnabled = config.isAutoCloseEnabled;
		this.markup = '';
		this.$markup = $();
		this.timer = null;
		this.autoClose = false;
		this.skipXSSValidation = false;
	}

	Flyout.prototype.open = function(params) {
		const placeSelector = (params && params.appendToElement) || 'body';

		this.skipXSSValidation = params.skipXSSValidation || false;
		this.$markup = $(getMarkup.call(this));
		this.$markup.appendTo($(placeSelector));
		this.initMarkup(params);
		this.isOpened = true;
		this.autoClose = params.autoClose && this.isAutoCloseEnabled;
		this.initCloseOnSwipe(this.type);

		if (this.autoClose) {
			this.closeFlyoutAutomatically.call(this);
		}

		handleAriaHiddenElements.call(this, true);
		$doc.trigger('flyout.opened', {
			type: this.type
		});
	};

	Flyout.prototype.close = function(params) {
		return new Promise((resolve) => {
			const flyout = this;
			const keepFocus = (params && params.keepFocus) || false;
			const openFlyoutOnAnimationEnd = (params && params.openFlyoutDeferred) || false;

			if (this.isOpened) {
				this.isOpened = false;
			} else {
				resolve();
			}

			$doc.off('keydown.' + this.type);
			$doc.off('click.' + this.type);
			$doc.off('dialog.opened.' + this.type);
			$doc.off('fancybox.closed.' + this.type);

			handleAriaHiddenElements.call(this, false);

			if (this.autoClose === true) {
				clearTimeout(this.timer);
				this.autoClose = false;
			}

			$doc.trigger('flyout.closing', {
				type: flyout.type
			});

			this.removeFromDOM(params).then(function() {
				if (!keepFocus && flyout.focusedElementBeforeFlyoutOpened) {
					flyout.focusedElementBeforeFlyoutOpened.focus();
				}

				app.flyoutMgr.flyoutIsClosed(flyout.type);
				$doc.trigger('flyout.closed', {
					type: flyout.type
				});

				if (openFlyoutOnAnimationEnd) {
					params.openFlyoutDeferred();
				}

				resolve();
			});
		});
	};

	Flyout.prototype.initMarkup = function(params) {
		this.$markup.find('.js-slide-flyout-close-button').on('click', this.close.bind(this, params));

		var fancyboxTriggerEl;

		var $content = this.$markup.find('.js-slide-flyout-content');
		var onOutsideClickHandlerFunc = onOutsideClickHandler.bind(this, params);

		if ($content.data('closeOnOutsideClick')) {
			this.$markup.addClass('m-closeonoutsideclick');
			$doc.on('click.' + this.type, onOutsideClickHandlerFunc);
		}

		$doc.on('keydown.' + this.type, onKeyDownHandler.bind(this));

		$doc.on('fancybox.updated.' + this.type, function() {
			if (params.fancybox !== true) {
				var $fancybox = $doc.find('.fancybox-wrap');

				params.fancybox = true;
				applyFocusFunctionality.call($fancybox, $fancybox.eq(0), params);
			}
		});

		$doc.on('dialog.opened.' + this.type, function() {
			$doc.off('keydown.' + this.type);
			$doc.off('click.' + this.type, onOutsideClickHandlerFunc);

			var $fancybox = $doc.find('.fancybox-wrap');
			$fancybox.on('keydown.fancybox', onKeyDownHandler.bind($fancybox));

			fancyboxTriggerEl = $(document.activeElement);
			toggleAriaHidden(this.$markup, true);
		}.bind(this));

		$doc.on('fancybox.beforeclose.' + this.type, function(e) {
			var $fancybox = $doc.find('.fancybox-wrap');
			var activeEl = e.target.activeElement;

			if (params && params.fancybox && ($.contains($fancybox.get(0), activeEl) || activeEl.tagName.toLowerCase() === 'body')) {
				delete params.fancybox;
			}

			$fancybox.off('keydown.fancybox');
		});

		$doc.on('fancybox.closed.' + this.type, function() {
			$doc.on('keydown.' + this.type, onKeyDownHandler.bind(this));
			toggleAriaHidden(this.$markup, false);

			if ($content.data('closeOnOutsideClick')) {
				$doc.on('click.' + this.type, onOutsideClickHandlerFunc);
			}

			if (fancyboxTriggerEl && fancyboxTriggerEl.length) {
				fancyboxTriggerEl.focus();
			}
		}.bind(this));

		this.$markup.addClass('m-show');
		$('html').addClass('html_flyout_opened l-flyout-opened-' + this.type);

		app.owlcarousel.initCarousel($content.find('.js-owl_carousel'));

		applyFocusFunctionality.call(this, this.$markup, params);

		this.$markup.on('animationend', function() {
			$doc.trigger('flyout.animationend');
		});
	};

	Flyout.prototype.removeFromDOM = function(params) {
		var deferred = $.Deferred();

		if (this.$markup instanceof jQuery && this.$markup.length > 0) {
			var animDuration = parseFloat(this.$markup.css('animation-duration'));
			this.$markup.removeClass('m-show').addClass('m-animation-out');

			if (animDuration === 0 || params.skipAnimation) {
				this.$markup.remove();
				$('html').removeClass('html_flyout_opened l-flyout-opened-' + this.type);
				deferred.resolve();
			} else {
				this.$markup.on('animationend', function() {
					this.$markup.remove();
					$('html').removeClass('html_flyout_opened l-flyout-opened-' + this.type);
					deferred.resolve();
				}.bind(this)).addClass('m-hide');
			}
		} else {
			deferred.resolve();
		}

		return deferred.promise();
	};

	Flyout.prototype.update = function(params) {
		this.autoClose = params.autoClose && this.isAutoCloseEnabled;
		this.initMarkup(params);

		if (this.autoClose) {
			this.closeFlyoutAutomatically.call(this);
		}
	};

	Flyout.prototype.closeFlyoutAutomatically = function() {
		var flyoutContext = this;
		clearTimeout(flyoutContext.timer);

		flyoutContext.timer = setTimeout(function() {
			flyoutContext.close();
		}, 5000);
	};

	Flyout.prototype.abortCloseFlyoutAutomatically = function() {
		clearTimeout(this.timer);
	};

	function getMarkup() {
		return app.util.renderTemplate($('#flyoutTemplate').html(), {
			type: this.type,
			markup: this.markup,
			direction: this.direction,
			closeBtnAriaLabel: this.closeBtnAriaLabel,
			closeBtnText: this.closeBtnText,
			skipXSSValidation: this.skipXSSValidation
		});
	}

	function applyFocusFunctionality($markup, params) {
		var isSafariBrowser = app.device.browser().safari;
		/**
		 * TODO: This should be done without check. By default browser set body as document.activeElement when
		 * non of the elements on the page is focused.
		 * We should implement something like focus on first element in DOM when page is loaded.
		 * https://allyjs.io/data-tables/focusable.html
		 * */

		if (params && params.lastFocus) {
			this.focusedElementBeforeFlyoutOpened = params.lastFocus;
		} else if (document.activeElement === document.body) {
			this.focusedElementBeforeFlyoutOpened = $('body').find(':focusable').get(0);
		} else if (isSafariBrowser) {
			this.focusedElementBeforeFlyoutOpened = $('body').find(':focusable').get(0); // Set default focused element as body for Safari browsers.
		} else {
			this.focusedElementBeforeFlyoutOpened = document.activeElement;
		}

		var $focusableElements = $markup.find(':focusable');
		var $elementToFocus = $markup.find(`[data-focusable-element-id="${params.focusableElementID}"]`);

		if ($focusableElements.length) {
			this.firstFocusableElement = $focusableElements.first().get(0);
			this.lastFocusableElement = $focusableElements.last().get(0);

			if ($elementToFocus.length) {
				$elementToFocus.focus();
			} else {
				this.firstFocusableElement.focus();
			}
		}

		if (params && params.fancybox) {
			this.type = 'fancybox';
		}

		$markup.on('keydown.' + this.type, onFocusKeyDownHandler.bind(this));
	}

	function handleAriaHiddenElements(state) {
		var ariaHiddenBlocksBelow = app.util.getConfig('flyoutComponent.ariaHiddenBlocksBelow');
		var isState = !!state;
		var bgBlockSelectors = !app.device.isMobileView() ? ariaHiddenBlocksBelow.desktop : ariaHiddenBlocksBelow.mobile;

		for (var i = 0, len = bgBlockSelectors.length; i < len; i++) {
			var $bgBlockSelector = $(bgBlockSelectors[i]);

			if ($bgBlockSelector.find(this.$markup).length) {
				toggleAriaHidden(this.$markup.siblings(':visible'), isState);
			} else {
				toggleAriaHidden($bgBlockSelector, isState);
			}
		}
	}

	function toggleAriaHidden($el, isState) {
		$el.attr('aria-hidden', isState).attr('tabindex', isState ? -1 : 0);
	}

	function onFocusKeyDownHandler(e) {
		var focusedBlock = this;
		var KEY_CODE_TAB = 9;

		if (e.keyCode === KEY_CODE_TAB) {
			if (e.shiftKey) {
				handleBackwardTab(e);
			} else {
				handleForwardTab(e);
			}
		}

		function handleBackwardTab(event) {
			if (document.activeElement === focusedBlock.firstFocusableElement) {
				event.preventDefault();
				focusedBlock.lastFocusableElement.focus();
			}
		}

		function handleForwardTab(event) {
			if (document.activeElement === focusedBlock.lastFocusableElement) {
				event.preventDefault();
				focusedBlock.firstFocusableElement.focus();
			}
		}
	}

	function onKeyDownHandler(e) {
		var KEY_CODE_ESC = 27;
		var KEY_CODE_ENTER = 13;
		var $flyoutContent;

		if (this.$markup) {
			$flyoutContent = this.$markup.find('.js-slide-flyout-content');
		}

		if (e.keyCode === KEY_CODE_ESC) {
			var $fancybox = $doc.find('.fancybox-wrap');

			if ($fancybox.length && $.contains($fancybox.get(0), e.target)) {
				app.fancybox.close();
				e.stopPropagation();
			} else if ($flyoutContent && $flyoutContent.length && $flyoutContent.data('closeOnEsc')) {
				this.close();
			}
		} else if (e.keyCode === KEY_CODE_ENTER) {
			if ($(e.target).hasClass('fancybox-close')) {
				e.preventDefault();
				app.fancybox.close();
			}
		}
	}

	function onOutsideClickHandler(params, e) {
		if ($(e.target).closest('.js-slide-flyout').length === 0 && !app.fancybox.isFancyboxEvent(e)) {
			this.close(params);
		}
	}

	const closeFlyoutTablet = (e, swipeAnchorAmount, flyout) => {
		var flyoutOffset = $(e.target).closest('.js-slide-flyout')[0].offsetTop;
		var scrollValue;

		if (e.type === 'touchmove') {
			var touch = e.originalEvent.touches[0] || e.originalEvent.changedTouches[0];

			scrollValue = touch.pageY;

			if (scrollValue > (flyoutOffset + swipeAnchorAmount)) {
				app.flyoutMgr.close(flyout);
			}
		}
	};

	Flyout.prototype.initCloseOnSwipe = function(type) {
		this.$markup.find('.js-slide-flyout_anchor').on('touchmove touchstart', function(e) {
			closeFlyoutTablet(e, 300, type);
		});
	};

	app.flyouts = {};
	app.flyouts.Flyout = Flyout;
})((window.app = window.app || {}), jQuery);
